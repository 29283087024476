<template>
  <div>
    <v-card>
      <v-card-title class="headline">
        Event Wizard
      </v-card-title>
      <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

      <v-expansion-panels v-if="event" v-model="wizardStep" accordion tile>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            <span>Step 1: <strong class="ml-1">Basic event setup</strong></span>
            <v-spacer/>
            <span v-if="stepInfoValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepInfoValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Please enter the generic details of your event. You can change this later.</p>
            <v-form ref="form" v-model="valid" lazy-validation>
              
              <v-text-field 
                  v-model="event.name" 
                  :rules="nameRules" 
                  label="Event name (required)" 
                  hint="The name of your event, preferable with the edition or year included."
                  prepend-icon="fa-pencil-alt"
                  required
                  />
              <v-text-field 
                  v-if="tenant !== 'cofi'"
                  v-model="event.url" 
                  :rules="urlRules" 
                  label="Event website (optional)" 
                  prepend-icon="fa-link"
                  />

              <v-radio-group v-if="event.type !== 'STEPS'" v-model="event.unit" row class="mt-0" persistent-hint hint="Set distance unit for this event. This overwrites user preferences." prepend-icon="fa-ruler">
                <v-radio label="Metric (kilometers)" value="METRIC"></v-radio>
                <v-radio label="Imperial (miles)" value="IMPERIAL"></v-radio>
              </v-radio-group>

              <div v-if="event.type !== 'RESULTS'">
                <v-input prepend-icon="fa-lock" class="mt-4 input-row" label="Require verification to join?" hide-details>
                  <template>
                    <v-btn-toggle v-model="calculatedVerificationMethod" mandatory class="multiline-btns">
                      <v-btn value="_" small>
                        None
                      </v-btn>
                      <v-btn value="REG_DATA" small>
                        Registration<br/>Data
                      </v-btn>
                      <v-btn value="ALLOWLIST" small>
                        Anonymous<br/>Codes
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                </v-input>
                <p class="ml-8 text-muted">
                  <span v-if="event.verification_method === 'ALLOWLIST'">Anyone with an anonymous (reusable) verification code may join. Please read documentation for instructions.</span>
                  <span v-else-if="event.verification_method === 'REG_DATA'">Manage registration data and/or upload Excel files to configure who may join this event. Only participants on the list may join. Please read documentation for instructions.</span>
                  <span v-else>This event is open. Everyone who has the link can join.</span>
                </p>
              </div>
            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepInfoValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepInfoValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-calendar-alt</v-icon>
            <span>Step 2: <strong class="ml-1">Event Window</strong></span>
            <v-spacer/>
            <span v-if="stepDatesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepDatesValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="event.type === 'RESULTS'" class="ml-8">Select the date of the event.</p>
            <p v-else class="ml-8">Select the event window. Only activities between open and close dates may count for this event. You may start inviting users to join before the start date and keep access to the data after the close date.</p>
            <v-row>
              <v-col cols="12" md="6" class="">
                <DateWithTimeZonePicker v-model="event.from" :timeZone="event.timezone" label="Start date (included)" @input="fromDateChanged"/>
              </v-col>
              <v-col cols="12" md="6" class="">
                <DateWithTimeZonePicker v-model="event.till" :timeZone="event.timezone" label="Last date (included)" />
              </v-col>
            </v-row>
              <v-text-field
                label="Time zone (read only)"
                class=""
                readonly
                disabled
                prepend-icon="fa fa-globe"
                hint="Users will always participate in their own local time zone."
                persistent-hint
                v-model="event.timezone"
                >
                <template v-slot:append>
                  <v-btn
                    v-if="!event.published"
                    text
                    color="primary"
                    class="ma-0"
                    @click="openTimezoneDialog">
                    {{$t('shared.edit')}}
                  </v-btn>
                </template>
              </v-text-field>

            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepDatesValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepDatesValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-receipt</v-icon>
            <span>Step 3: <strong class="ml-1">License</strong></span>
            <v-spacer/>
            <span v-if="stepLicenseValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-else class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Select the license for this event. No payment is needed at this time. You can change this later.</p>

            <v-row class="pricing-details">
              
              <v-col cols="12" sm="6" md="4">
                <v-card :dark="license=='BASIC'" :color="license=='BASIC' ? 'primary' : 'white'" class="pa-4" @click="license='BASIC'">
                  <h3 class="text-center">Starter</h3>
                  <p>All the good stuff you need to host a successful fitness challenge.</p>
                  <v-divider/>
                  <ul class="check-list">
                    <li>All challenge modes</li>
                    <li>All device integrations</li>
                    <li>Team leaderboards</li>
                    <li>Virtual course maps</li>
                    <li>Self-service dashboard to manage events</li>
                  </ul>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card :dark="license=='PRO'" :color="license=='PRO' ? 'primary' : 'white'" class="pa-4 popular" @click="license='PRO'">
                  <h3 class="text-center">Pro <v-chip small outlined :color="license=='PRO' ? 'white' : 'black'">Most Popular</v-chip></h3>
                  <p>Advanced features to host engaging events for larger teams.</p>
                  <v-divider/>
                  <div class="align-left">All from <strong>Starter</strong>, plus:</div>
                  <ul class="check-list">
                    <li>Customizable branding</li>
                    <li>Automatic step conversions</li>
                    <li>Microsoft Teams Integration</li>
                    <li>Slack Integration</li>
                    <li>Excel Report Export</li>
                    <li>Multiple co-admins</li>
                  </ul>

                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-card :dark="license=='ENTERPRISE'" :color="license=='ENTERPRISE' ? 'primary' : 'white'" class="pa-4" @click="license='ENTERPRISE'">
                  <h3 class="text-center">Enterprise</h3>
                  <p>Enhanced security, SSO, integrations, security checklists, and DPAs.</p>
                  <v-divider/>
                  <div class="align-left">All from <strong>PRO</strong>, plus:</div>
                  <ul class="check-list">
                    <li>Single Sign-On</li>
                    <li>Custom DPA</li>
                    <li>Security Checklists</li>
                    <li>API and Zapier support</li>
                    <li>Advanced security features</li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>
            <div class="align-center mt-8">
              <v-btn outlined rounded color="primary" to="/pricing" target="_blank">Show detailed plan comparison</v-btn>
            </div>
            
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepLicenseValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepLicenseValid" class="" :disabled="!stepLicenseValid" color="primary" :loading="$store.getters.isLoading" @click="saveLicense">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="eventUtil.hasMeta('cfg.template.id')">
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
            <span>Step 4: <strong class="ml-1">Template Configuration</strong></span>
            <v-spacer/>
            <span v-if="stepRacesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepRacesValid" class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link :to="{name:'eventmanagerConfigTemplate', params: {id: event.id}, query: {template: eventUtil.getMetaStringValue('cfg.template.id')}}">OPEN EDITOR</router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-else>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-trophy</v-icon>
            <span>Step 4: <strong class="ml-1">Leaderboards</strong></span>
            <v-spacer/>
            <span v-if="stepRacesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepRacesValid" class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <p class="d-flex">
              <span>Add one or more leaderboards to your event. You can always change this later on.</span>
              <v-spacer/>
              <v-switch v-if="!isCofi" v-model="advancedRaceOptions" hide-details label="Show advanced options" class="mt-0"/>
            </p>
              <v-row class="mb-4">
                <v-col
                  v-for="(item, idx) in event.races"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card :color="$helpers.getGradientColor(idx)">
                    <v-card-title class="text--bold subtitle">
                      {{item.name}}
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <div>Type: {{ $t('events.rules.scoring-methods-short.' + item.scoring) }}</div>
                      <div>Goal: {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event) }}</div>
                      <div>Sports:             
                      <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
                      <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
                        <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                        <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
                      </span>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn v-if="item.type !== 'EXTERNAL'" text @click="$refs.raceDialog.editRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-pencil-alt</v-icon> Edit
                      </v-btn>
                      <v-spacer/>
                      <v-btn text @click="deleteRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-trash</v-icon> Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            <p v-if="!showAddRacePanel">
              <v-btn color="primary" outlined @click="showAddRacePanel = !showAddRacePanel">
                <v-icon class="mr-2">fa-plus-circle</v-icon> Add Race
              </v-btn>
            </p>

            <div v-if="isCofi">
              <v-btn color="primary" outlined class="mr-4 mb-4" @click="$refs.addRaceDialog.open()">
                <v-icon small class="mr-2">fa-plus</v-icon> Add Leaderboard
              </v-btn>
              <p class="text-muted">Tip: more options will be available after completing this wizard.</p>
            </div>
            <div v-else-if="showAddRacePanel">
              <p>
                Add Standard Distance: <v-btn v-for="(item,idx) in defaultRaces.filter(x => !event.races.some(y => x.dist === y.dist))" :key="idx" color="primary" class="px-2" style="min-width:10px !important;" text @click="$refs.raceDialog.addRaceAndSave(item)">{{item.name}}</v-btn>
              </p>
              <v-btn color="primary" outlined @click="$refs.addRaceDialog.open()">
                <v-icon small class="mr-2">fa-plus-circle</v-icon> Add Leaderboard
              </v-btn>
              <v-btn v-if="false" color="primary" text @click="showAddRacePanel = false">Cancel</v-btn>
            </div>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepRacesValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepRacesValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-paper-plane</v-icon>
            <span>Step 5: <strong class="ml-1">Submit</strong></span>
            <v-spacer/>
            <span v-if="readyToSubmit" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-else class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Submit your event details.</p>
            <v-form ref="sumitForm" v-model="valid" lazy-validation>
              <v-text-field 
                v-model="event.email" 
                label="Your contact email (not visible to users)" 
                :hint="`Only used by ${tenant.name} to inform you of product updates and in case we need to contact you. This will not be visible to users.`"
                prepend-icon="fa-envelope"
                :rules="emailRules"
                type="email"
                required
                />

              <!-- <v-textarea
                v-model="event.remarks"
                label="Enter any remarks, comments, or optional implementation notes. These are only visible for (co-)admins and not publicly:"
                prepend-icon="fa-comment"
                /> -->

              <v-alert v-if="!readyToSubmit" type="warning">One or more steps are not yet completed. Please complete all steps before you can submit this event information.</v-alert>

              <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
                <v-spacer />
                <v-btn :disabled="!readyToSubmit" class="" color="primary" :loading="$store.getters.isLoading" @click="submit">Submit</v-btn>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card>
    
    <v-dialog v-model="showTimezoneDialog" max-width="500">
      <v-card>
        <v-card-title>Modify Event Time Zone</v-card-title>
        <v-card-text>
          <p>Changing the time zone of this event will also convert all dates into this new time zone.</p>
          <v-autocomplete v-model="selectedTimezone" :items="timezoneList" /> 
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="showTimezoneDialog=false">Close</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="setTimezone">Update</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <AddRaceDialog ref="addRaceDialog" :event="event" @saved="refresh" />
    <RaceDialog ref="raceDialog" :event="event" @save="save" :simple="!advancedRaceOptions"/>
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import ActivityUploadForm from "@/components/ActivityUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import EventHeader from "@/components/EventHeader.vue";
import AddRaceDialog from './_AddRaceDialog.vue'
import RaceDialog from './_RaceDialog.vue'
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ActivityUploadForm,
    SupportButton,
    Header,
    RaceDialog,
    AddRaceDialog,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      wizardStep: tenant.id === 'cofi' ? 1 : 0,
      valid: true,
      savedSnackbar: false,
      error: null,
      event: null,
      advancedRaceOptions: false,
      showTimezoneDialog: false,
      selectedTimezone: null,
      license: null,
      currency: 'USD',
      products: siteData.products,
      timezoneList: [],
      nameRules: [
        v => !!v || "Please enter the event name",
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      optionalEmailRules: [
        v => !v || /.+@.+/.test(v) || 'Enter a valid email address'
      ],
      urlRules: [
        v => !v || v.startsWith('https://') || "Website must start with 'https://' (http is not supported!)",
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      showAddRacePanel: true,
      defaultRaces: [
        { name: '5k', scoring: 'RESULT', dist: 5000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10k', scoring: 'RESULT', dist: 10000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10 miles', scoring: 'RESULT', dist: 16093, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Half marathon', scoring: 'RESULT', dist: 21097, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Marathon', scoring: 'RESULT', dist: 42195, activity_types: ['RUNNING', 'WALKING'] },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
    EventBus.$on('eventmanager-refresh', async state => {
      console.log('refresh requested', state);
      this.event = state.event; // force refresh down the tree
      this.license = this.event.license;
    });
  },
  methods: {
    async skip() {
      this.wizardStep++;
    },
    async next() {
      await this.save();
      this.wizardStep++;
    },
    async back() {
      this.wizardStep--;
    },
    async refresh() {
      this.event = this.eventUtil.event;
    },
    async saveLicense() {
      let result = await eventManagerService.applyLicense(this.event.id, this.license);
      if (result.data.status == 'OK') {
        await this.eventUtil.refresh();
        this.wizardStep++;
      }
      else {
        this.$helpers.toastResponse(this, result.data, 'Event Saved Successfully.');
      }
    },
    async save() {
      if (!this.$refs.form || this.$refs.form.validate()) {
        this.error = null;
        try {
          //console.log('////', this.event);
          this.eventUtil.event = this.event;
          //var response = (await eventManagerService.put(this.event)).data;
          await this.eventUtil.save();
          //this.$helpers.toastResponse(this, {status: 'OK'}, 'Event Saved Successfully.');
          //await this.getEvent(response.data.id);
          //this.$router.push({ name: 'eventmanagerView', params: { id: response.data.id }});
        }
        catch (ex) {
          this.$helpers.toastResponse(this, {status: 'ERROR', msg: 'Error saving event, please check all data for missing or incorrect values.' });
//          this.error = ex.response.data.msg;
        }
      }
    },
    async submit() {
      if (!this.$refs.sumitForm || this.$refs.sumitForm.validate()) {
        this.error = null;
        try {
          //var response = (await eventManagerService.put(this.event)).data;
//          this.$helpers.toastResponse(this, {status: 'OK'}, 'Event Saved Successfully.');
          this.eventUtil.event = this.event;
          await this.eventUtil.save();
          //await this.getEvent(response.data.id);
          this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
        }
        catch (ex) {
          this.$helpers.toastResponse(this, {status: 'ERROR', msg: 'Error saving event, please check all data for missing or incorrect values.' });
          //this.error = ex.response.data.msg;
        }
      }
    },

    async fromDateChanged(value) {
      if (this.event.type === 'RESULTS') {
        // sync them to simplify setup
        this.event.till = value;
      }
    },

    async openTimezoneDialog() {
      this.timezoneList = Intl.supportedValuesOf('timeZone');
      this.selectedTimezone = this.event.timezone;
      this.showTimezoneDialog = true;
    },

    async setTimezone() {
      this.event.timezone = this.selectedTimezone;
      this.showTimezoneDialog = false;
    },

    async deleteRace (item) {
      const index = this.event.races.indexOf(item);
      confirm('Are you sure you want to DELETE THIS RACE AND ALL RESULTS?') && this.event.races.splice(index, 1) && await this.save();
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.event.timezone = this.event.timezone || DateTime.now().zoneName;
      this.license = this.event.license;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    isCofi() {
      return tenant.id === 'cofi';
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    readyToSubmit() {
      return this.stepInfoValid && this.stepDatesValid && this.stepSubmitValid;
    },
    stepInfoValid() {
      return this.event && this.event.name;
    },
    stepDatesValid() {
      return this.event && this.event.from && this.event.till;
    },
    stepRacesValid() {
      return this.event && this.event.races && this.event.races.length;
    },
    stepLicenseValid() {
      return this.license && this.license != 'FREE';
    },
    stepSubmitValid() {
      return this.event && this.event.email;
    },
    calculatedVerificationMethod:{
      get () {
        return !this.event || !this.event.verification_method ? '_' : this.event.verification_method
      },
      set (value) {
        this.event.verification_method = value === '_' ? null : value;
        this.$forceUpdate();
      }
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Setup Wizard', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
.v-expansion-panel-header .v-icon { width: 24px; margin-left: -8px; }
  .pricing-details {
    .v-sheet { text-align: center;; }
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .small { font-size: 75%; display: inline-block; text-align: left;}
    .large { font-size: 200%;}
    .large + .small { margin-left: 5px; }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
</style>

